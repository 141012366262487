
.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

  .btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px
  }

  .btn.focus, .btn:focus, .btn:hover {
    color: #333;
    text-decoration: none
  }

  .btn.active, .btn:active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
    box-shadow: inset 0 3px 5px rgba(0,0,0,.125)
  }

  .btn.disabled, .btn[disabled], fieldset[disabled] .btn {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    opacity: .65;
    -webkit-box-shadow: none;
    box-shadow: none
  }

a.btn.disabled, fieldset[disabled] a.btn {
  pointer-events: none
}

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc
}

  .btn-default.focus, .btn-default:focus {
    color: #333;
    background-color: #e6e6e6;
    border-color: #8c8c8c
  }

  .btn-default:hover {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad
  }

  .btn-default.active, .btn-default:active, .open > .dropdown-toggle.btn-default {
    color: #333;
    background-color: #e6e6e6;
    background-image: none;
    border-color: #adadad
  }

    .btn-default.active.focus, .btn-default.active:focus, .btn-default.active:hover, .btn-default:active.focus, .btn-default:active:focus, .btn-default:active:hover, .open > .dropdown-toggle.btn-default.focus, .open > .dropdown-toggle.btn-default:focus, .open > .dropdown-toggle.btn-default:hover {
      color: #333;
      background-color: #d4d4d4;
      border-color: #8c8c8c
    }

  .btn-default.disabled.focus, .btn-default.disabled:focus, .btn-default.disabled:hover, .btn-default[disabled].focus, .btn-default[disabled]:focus, .btn-default[disabled]:hover, fieldset[disabled] .btn-default.focus, fieldset[disabled] .btn-default:focus, fieldset[disabled] .btn-default:hover {
    background-color: #fff;
    border-color: #ccc
  }

  .btn-default .badge {
    color: #fff;
    background-color: #333
  }

.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4
}

  .btn-primary.focus, .btn-primary:focus {
    color: #fff;
    background-color: #286090;
    border-color: #122b40
  }

  .btn-primary:hover {
    color: #fff;
    background-color: #286090;
    border-color: #204d74
  }

  .btn-primary.active, .btn-primary:active, .open > .dropdown-toggle.btn-primary {
    color: #fff;
    background-color: #286090;
    background-image: none;
    border-color: #204d74
  }

    .btn-primary.active.focus, .btn-primary.active:focus, .btn-primary.active:hover, .btn-primary:active.focus, .btn-primary:active:focus, .btn-primary:active:hover, .open > .dropdown-toggle.btn-primary.focus, .open > .dropdown-toggle.btn-primary:focus, .open > .dropdown-toggle.btn-primary:hover {
      color: #fff;
      background-color: #204d74;
      border-color: #122b40
    }

  .btn-primary.disabled.focus, .btn-primary.disabled:focus, .btn-primary.disabled:hover, .btn-primary[disabled].focus, .btn-primary[disabled]:focus, .btn-primary[disabled]:hover, fieldset[disabled] .btn-primary.focus, fieldset[disabled] .btn-primary:focus, fieldset[disabled] .btn-primary:hover {
    background-color: #337ab7;
    border-color: #2e6da4
  }

  .btn-primary .badge {
    color: #337ab7;
    background-color: #fff
  }

.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c
}

  .btn-success.focus, .btn-success:focus {
    color: #fff;
    background-color: #449d44;
    border-color: #255625
  }

  .btn-success:hover {
    color: #fff;
    background-color: #449d44;
    border-color: #398439
  }

  .btn-success.active, .btn-success:active, .open > .dropdown-toggle.btn-success {
    color: #fff;
    background-color: #449d44;
    background-image: none;
    border-color: #398439
  }

    .btn-success.active.focus, .btn-success.active:focus, .btn-success.active:hover, .btn-success:active.focus, .btn-success:active:focus, .btn-success:active:hover, .open > .dropdown-toggle.btn-success.focus, .open > .dropdown-toggle.btn-success:focus, .open > .dropdown-toggle.btn-success:hover {
      color: #fff;
      background-color: #398439;
      border-color: #255625
    }

  .btn-success.disabled.focus, .btn-success.disabled:focus, .btn-success.disabled:hover, .btn-success[disabled].focus, .btn-success[disabled]:focus, .btn-success[disabled]:hover, fieldset[disabled] .btn-success.focus, fieldset[disabled] .btn-success:focus, fieldset[disabled] .btn-success:hover {
    background-color: #5cb85c;
    border-color: #4cae4c
  }

  .btn-success .badge {
    color: #5cb85c;
    background-color: #fff
  }

.btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da
}

  .btn-info.focus, .btn-info:focus {
    color: #fff;
    background-color: #31b0d5;
    border-color: #1b6d85
  }

  .btn-info:hover {
    color: #fff;
    background-color: #31b0d5;
    border-color: #269abc
  }

  .btn-info.active, .btn-info:active, .open > .dropdown-toggle.btn-info {
    color: #fff;
    background-color: #31b0d5;
    background-image: none;
    border-color: #269abc
  }

    .btn-info.active.focus, .btn-info.active:focus, .btn-info.active:hover, .btn-info:active.focus, .btn-info:active:focus, .btn-info:active:hover, .open > .dropdown-toggle.btn-info.focus, .open > .dropdown-toggle.btn-info:focus, .open > .dropdown-toggle.btn-info:hover {
      color: #fff;
      background-color: #269abc;
      border-color: #1b6d85
    }

  .btn-info.disabled.focus, .btn-info.disabled:focus, .btn-info.disabled:hover, .btn-info[disabled].focus, .btn-info[disabled]:focus, .btn-info[disabled]:hover, fieldset[disabled] .btn-info.focus, fieldset[disabled] .btn-info:focus, fieldset[disabled] .btn-info:hover {
    background-color: #5bc0de;
    border-color: #46b8da
  }

  .btn-info .badge {
    color: #5bc0de;
    background-color: #fff
  }

.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236
}

  .btn-warning.focus, .btn-warning:focus {
    color: #fff;
    background-color: #ec971f;
    border-color: #985f0d
  }

  .btn-warning:hover {
    color: #fff;
    background-color: #ec971f;
    border-color: #d58512
  }

  .btn-warning.active, .btn-warning:active, .open > .dropdown-toggle.btn-warning {
    color: #fff;
    background-color: #ec971f;
    background-image: none;
    border-color: #d58512
  }

    .btn-warning.active.focus, .btn-warning.active:focus, .btn-warning.active:hover, .btn-warning:active.focus, .btn-warning:active:focus, .btn-warning:active:hover, .open > .dropdown-toggle.btn-warning.focus, .open > .dropdown-toggle.btn-warning:focus, .open > .dropdown-toggle.btn-warning:hover {
      color: #fff;
      background-color: #d58512;
      border-color: #985f0d
    }

  .btn-warning.disabled.focus, .btn-warning.disabled:focus, .btn-warning.disabled:hover, .btn-warning[disabled].focus, .btn-warning[disabled]:focus, .btn-warning[disabled]:hover, fieldset[disabled] .btn-warning.focus, fieldset[disabled] .btn-warning:focus, fieldset[disabled] .btn-warning:hover {
    background-color: #f0ad4e;
    border-color: #eea236
  }

  .btn-warning .badge {
    color: #f0ad4e;
    background-color: #fff
  }

.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a
}

  .btn-danger.focus, .btn-danger:focus {
    color: #fff;
    background-color: #c9302c;
    border-color: #761c19
  }

  .btn-danger:hover {
    color: #fff;
    background-color: #c9302c;
    border-color: #ac2925
  }

  .btn-danger.active, .btn-danger:active, .open > .dropdown-toggle.btn-danger {
    color: #fff;
    background-color: #c9302c;
    background-image: none;
    border-color: #ac2925
  }

    .btn-danger.active.focus, .btn-danger.active:focus, .btn-danger.active:hover, .btn-danger:active.focus, .btn-danger:active:focus, .btn-danger:active:hover, .open > .dropdown-toggle.btn-danger.focus, .open > .dropdown-toggle.btn-danger:focus, .open > .dropdown-toggle.btn-danger:hover {
      color: #fff;
      background-color: #ac2925;
      border-color: #761c19
    }

  .btn-danger.disabled.focus, .btn-danger.disabled:focus, .btn-danger.disabled:hover, .btn-danger[disabled].focus, .btn-danger[disabled]:focus, .btn-danger[disabled]:hover, fieldset[disabled] .btn-danger.focus, fieldset[disabled] .btn-danger:focus, fieldset[disabled] .btn-danger:hover {
    background-color: #d9534f;
    border-color: #d43f3a
  }

  .btn-danger .badge {
    color: #d9534f;
    background-color: #fff
  }

.btn-link {
  font-weight: 400;
  color: #337ab7;
  border-radius: 0
}

  .btn-link, .btn-link.active, .btn-link:active, .btn-link[disabled], fieldset[disabled] .btn-link {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none
  }

    .btn-link, .btn-link:active, .btn-link:focus, .btn-link:hover {
      border-color: transparent
    }

      .btn-link:focus, .btn-link:hover {
        color: #23527c;
        text-decoration: underline;
        background-color: transparent
      }

      .btn-link[disabled]:focus, .btn-link[disabled]:hover, fieldset[disabled] .btn-link:focus, fieldset[disabled] .btn-link:hover {
        color: #777;
        text-decoration: none
      }

.btn-group-lg > .btn, .btn-lg {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px
}

.btn-group-sm > .btn, .btn-sm {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px
}

.btn-group-xs > .btn, .btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px
}

.btn-block {
  display: block;
  width: 100%
}

  .btn-block + .btn-block {
    margin-top: 5px
  }

input[type=button].btn-block, input[type=reset].btn-block, input[type=submit].btn-block {
  width: 100%
}

.input-group .form-control {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0
}

  .input-group .form-control:focus {
    z-index: 3
  }

.input-group-lg > .form-control, .input-group-lg > .input-group-addon, .input-group-lg > .input-group-btn > .btn {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px
}

select.input-group-lg > .form-control, select.input-group-lg > .input-group-addon, select.input-group-lg > .input-group-btn > .btn {
  height: 46px;
  line-height: 46px
}

select[multiple].input-group-lg > .form-control, select[multiple].input-group-lg > .input-group-addon, select[multiple].input-group-lg > .input-group-btn > .btn, textarea.input-group-lg > .form-control, textarea.input-group-lg > .input-group-addon, textarea.input-group-lg > .input-group-btn > .btn {
  height: auto
}

.input-group-sm > .form-control, .input-group-sm > .input-group-addon, .input-group-sm > .input-group-btn > .btn {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px
}

select.input-group-sm > .form-control, select.input-group-sm > .input-group-addon, select.input-group-sm > .input-group-btn > .btn {
  height: 30px;
  line-height: 30px
}

select[multiple].input-group-sm > .form-control, select[multiple].input-group-sm > .input-group-addon, select[multiple].input-group-sm > .input-group-btn > .btn, textarea.input-group-sm > .form-control, textarea.input-group-sm > .input-group-addon, textarea.input-group-sm > .input-group-btn > .btn {
  height: auto
}

.input-group .form-control, .input-group-addon, .input-group-btn {
  display: table-cell
}

  .input-group .form-control:not(:first-child):not(:last-child), .input-group-addon:not(:first-child):not(:last-child), .input-group-btn:not(:first-child):not(:last-child) {
    border-radius: 0
  }

.input-group-addon, .input-group-btn {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle
}

.input-group-addon {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 4px
}

  .input-group-addon.input-sm {
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 3px
  }

  .input-group-addon.input-lg {
    padding: 10px 16px;
    font-size: 18px;
    border-radius: 6px
  }

  .input-group-addon input[type=checkbox], .input-group-addon input[type=radio] {
    margin-top: 0
  }

  .input-group .form-control:first-child, .input-group-addon:first-child, .input-group-btn:first-child > .btn, .input-group-btn:first-child > .btn-group > .btn, .input-group-btn:first-child > .dropdown-toggle, .input-group-btn:last-child > .btn-group:not(:last-child) > .btn, .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  .input-group-addon:first-child {
    border-right: 0
  }

  .input-group .form-control:last-child, .input-group-addon:last-child, .input-group-btn:first-child > .btn-group:not(:first-child) > .btn, .input-group-btn:first-child > .btn:not(:first-child), .input-group-btn:last-child > .btn, .input-group-btn:last-child > .btn-group > .btn, .input-group-btn:last-child > .dropdown-toggle {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s,-webkit-box-shadow ease-in-out .15s
}

  .form-control:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6)
  }

  .form-control::-moz-placeholder {
    color: #999;
    opacity: 1
  }

  .form-control:-ms-input-placeholder {
    color: #999
  }

  .form-control::-webkit-input-placeholder {
    color: #999
  }

  .form-control::-ms-expand {
    background-color: transparent;
    border: 0
  }

  .form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
    background-color: #eee;
    opacity: 1
  }

  .form-control[disabled], fieldset[disabled] .form-control {
    cursor: not-allowed
  }

textarea.form-control {
  height: auto
}



.form-group {
  margin-bottom: 15px
}
